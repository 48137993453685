import React, { useState, useEffect } from 'react';
import { DateWrapper, FormContainer, GridContainer, ImageWrapper, Title, Wrapper } from '../components/layout/styles';
import InputContainer from '../global/InputContainer';
import name from '../assets/svg/name.svg';
import company from '../assets/svg/company.svg';
import link from '../assets/svg/link.svg';
import TextareaContainer from '../global/TextareaContainer';
import description from '../assets/svg/description.svg';
import { Button, ButtonOutlined, CheckboxWrapper, IconSelect, IconWrapper, CSVLinkOutlined } from '../global/atoms';
import AddFile from '../global/AddFile';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import QRCode from 'qrcode.react';
import { useParams } from 'react-router';
import { Connections } from '../utils/connections';
import Select from 'react-select';

const customSelect = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    zIndex: '98',
    background: '#F0F4F8',
    color: '#748A9D',
    borderRadius: '8px',
    fontSize: '14px',
  }),
  control: (provided) => ({
    ...provided,
    color: '#748A9D',
    background: '#F0F4F8',
    borderRadius: '8px',
    paddingLeft: '28px',
    fontSize: '14px',
    border: 'none',
  }),
  input: (provided) => ({
    ...provided,
    color: '#748A9D',
    fontSize: '14px',
    border: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#748A9D',
    fontSize: '14px',
  }),
};

const ProjectView = ({ setProjectId, setProjects }) => {
  const [data, setData] = useState('loading');
  const [loading, setLoading] = useState(false);
  const [qr, setQr] = useState('');
  const { id: projectId } = useParams();
  const [companies, setCompanies] = useState(null);

  useEffect(() => {
    Connections.getCompanies().then((data) => {
      const filtred = data.data.map(({ name, id }) => ({ value: id.toString(), label: name }));
      setCompanies(filtred);
    });
  }, []);

  useEffect(() => {
    toast
      .promise(Connections.getProject(projectId), {
        success: 'Pobrano dane projektu',
        loading: 'Ładowanie...',
        error: 'Błąd pobierania danych projektu',
      })
      .then((data) =>
        setData({
          company: data.data.company?.id,
          description: data.data.description,
          frontContent: data.data.frontContent || { url: '' },
          frontMarker: data.data.frontMarker || { url: '' },
          leftContent: data.data.leftContent || { url: '' },
          leftMarker: data.data.leftMarker || { url: '' },
          logo: data.data.logo || { url: '' },
          name: data.data.name,
          rightContent: data.data.rightContent || { url: '' },
          rightMarker: data.data.rightMarker || { url: '' },
          startDate: data.data.startDate,
          endDate: data.data.endDate,
          url: data.data.url,
          frontVisible: data.data.frontVisible,
          leftVisible: data.data.leftVisible,
          rightVisible: data.data.rightVisible,
          deepLinkTag: data.data.deepLinkTag,
        }),
      );
  }, [projectId]);

  useEffect(() => {
    if (data !== 'loading') {
      const canvas = document.querySelector('.qr-canvas');
      const ctx = canvas.getContext('2d');
      const ox = canvas.width / 2;
      const oy = canvas.height / 2;

      ctx.font = '42px serif';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = '#fff';
      ctx.fillRect(ox / 2, oy / 2, ox, oy);

      const image = canvas.toDataURL('image/jpg');
      setQr(image);
    }
  }, [data]);

  const handleChangeInput = (event) => {
    setData({ ...data, [event.currentTarget.name]: event.currentTarget.value });
  };
  const handleChangeCheckbox = (event) => {
    setData({ ...data, [event.currentTarget.name]: event.currentTarget.checked });
  };

  const handleChangeFile = (event) => {
    if (typeof event === 'string') {
      setData((state) => ({
        ...state,
        [event]: { url: '' },
      }));
    } else {
      const file = event.currentTarget.files[0];

      const reader = new FileReader();

      reader.onload = ({ target }) => {
        if (target) {
          setData((state) => ({
            ...state,
            [event.target.name]: {
              file: file,
              url: target.result,
            },
          }));
        }
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const handleDelete = () => {
    if (window.confirm('Czy jesteś pewien, że chcesz usunąć projekt?')) {
      setLoading(true);

      toast
        .promise(Connections.deleteProject(projectId), {
          success: 'Projekt usunięty',
          loading: 'Ładowanie...',
          error: 'Błąd usuwania projektu',
        })
        .then(() => {
          setLoading(false);
          window.location.replace('/');
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleSubmit = () => {
    const form = new FormData();
    for (const item in data) {
      if (data[item]?.file) {
        form.append(`files.${item}`, data[item].file, data[item].file.name);
      } else if (
        item === 'company' ||
        item === 'description' ||
        item === 'endDate' ||
        item === 'startDate' ||
        item === 'name'
      ) {
        form.append(item, data[item]);
      }
    }

    form.append('data', JSON.stringify(data));

    setLoading(true);

    toast
      .promise(Connections.updateProject(projectId, form), {
        success: 'Zapisano zmiany w projekcie',
        loading: 'Ładowanie...',
        error: 'Błąd zapisywania zmian w projekcie',
      })
      .then(() => {
        setLoading(false);
        window.location.replace('/');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (data === 'loading') return null;

  return (
    <>
      {loading && (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.3)',
            zIndex: 99,
            top: 0,
            left: 0,
          }}
        />
      )}
      <FormContainer>
        <Wrapper
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gridRowGap: '12px',
          }}
        >
          <Title>Dane Projektu</Title>
          <InputContainer
            type={'text'}
            onChange={handleChangeInput}
            icon={name}
            value={data.name}
            placeholder={'Nazwa projektu'}
            name={'name'}
          />
          {companies && data && (
            <IconWrapper style={{ position: 'relative', width: '100%' }}>
              <Select
                name="company"
                defaultValue={companies?.filter((el) => +el.value === +data.company)[0]}
                options={companies}
                onChange={({ value }) => setData({ ...data, company: value })}
                styles={customSelect}
              />
              <IconSelect src={company} />
            </IconWrapper>
          )}
          <InputContainer
            icon={link}
            type={'text'}
            onChange={handleChangeInput}
            value={data.url}
            placeholder={'Link w aplikacji'}
            name={'url'}
          />
          <TextareaContainer
            onChange={handleChangeInput}
            icon={description}
            value={data.description}
            placeholder={'Opis firmy'}
            name={'description'}
          />
          <Title>Projekt ograniczony czasowo</Title>
          <DateWrapper>
            <Wrapper style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
              <Title style={{ marginRight: '10px' }}>Od</Title>
              <InputContainer
                type={'date'}
                onChange={handleChangeInput}
                value={data.startDate}
                placeholder={'Data rozpoczęcia'}
                name={'startDate'}
              />
            </Wrapper>
            <Wrapper style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
              <Title style={{ marginRight: '10px' }}>Do</Title>
              <InputContainer
                type={'date'}
                onChange={handleChangeInput}
                value={data.endDate}
                placeholder={'Data zakończenia'}
                name={'endDate'}
              />
            </Wrapper>
          </DateWrapper>
          <ImageWrapper>
            <Title>Logo</Title>
            <AddFile name={'logo'} handleChangeFile={handleChangeFile} data={data} visible={true} />
          </ImageWrapper>
        </Wrapper>
        <Wrapper
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gridRowGap: '12px',
          }}
        >
          <Title>Pudełko</Title>
          <GridContainer>
            <ImageWrapper>
              <CheckboxWrapper>
                <Title>Widoczny</Title>
                <InputContainer
                  type={'checkbox'}
                  onChange={handleChangeCheckbox}
                  value={data.leftVisible}
                  name={'leftVisible'}
                  style={{ width: '1rem' }}
                />
              </CheckboxWrapper>
              <Title>Znacznik lewej ściany</Title>
              <AddFile name={'leftMarker'} handleChangeFile={handleChangeFile} data={data} visible={data.leftVisible} />
            </ImageWrapper>
            <ImageWrapper>
              <CheckboxWrapper>
                <Title>Widoczny</Title>
                <InputContainer
                  type={'checkbox'}
                  onChange={handleChangeCheckbox}
                  value={data.frontVisible}
                  name={'frontVisible'}
                  style={{ width: '1rem' }}
                />
              </CheckboxWrapper>
              <Title>Znacznik przedniej ściany</Title>
              <AddFile
                name={'frontMarker'}
                handleChangeFile={handleChangeFile}
                data={data}
                visible={data.frontVisible}
              />
            </ImageWrapper>
            <ImageWrapper>
              <CheckboxWrapper>
                <Title>Widoczny</Title>
                <InputContainer
                  type={'checkbox'}
                  onChange={handleChangeCheckbox}
                  value={data.rightVisible}
                  name={'rightVisible'}
                  style={{ width: '1rem' }}
                />
              </CheckboxWrapper>
              <Title>Znacznik prawej ściany</Title>
              <AddFile
                name={'rightMarker'}
                handleChangeFile={handleChangeFile}
                data={data}
                visible={data.rightVisible}
              />
            </ImageWrapper>
          </GridContainer>
          <GridContainer>
            <ImageWrapper>
              <Title>Materiał graficzny - ściana lewa</Title>
              <AddFile
                type={'video'}
                name={'leftContent'}
                handleChangeFile={handleChangeFile}
                data={data}
                visible={data.leftVisible}
              />
            </ImageWrapper>
            <ImageWrapper>
              <Title>Materiał graficzny - ściana przednia</Title>
              <AddFile
                type={'video'}
                name={'frontContent'}
                handleChangeFile={handleChangeFile}
                data={data}
                visible={data.frontVisible}
              />
            </ImageWrapper>
            <ImageWrapper>
              <Title>Materiał graficzny - ściana prawa</Title>
              <AddFile
                type={'video'}
                name={'rightContent'}
                handleChangeFile={handleChangeFile}
                data={data}
                visible={data.rightVisible}
              />
            </ImageWrapper>
          </GridContainer>
          <a
            href={qr}
            id={'download'}
            style={{ display: 'block', margin: '0 auto' }}
            download={`${data.name.trim().replaceAll(' ', '_')}.png`}
          >
            <QRCode
              value={`https://polandinthebox.page.link/${data.deepLinkTag}`}
              className={'qr-canvas'}
              // renderAs={'svg'}
              level={'H'}
              size={1000}
              imageSettings={{
                width: 100,
                height: 100,
              }}
              style={{ width: '250px', height: 'auto' }}
            />
          </a>
        </Wrapper>
      </FormContainer>
      <div style={{ position: 'fixed', bottom: '20px', right: '20px', display: 'flex' }}>
        <Button onClick={handleSubmit} style={{ marginRight: '10px' }}>
          Zapisz
        </Button>
        <ButtonOutlined onClick={handleDelete}>Usuń</ButtonOutlined>
      </div>
    </>
  );
};

export default ProjectView;
