import { Link } from 'react-router-dom';
import styled, { createGlobalStyle, css } from 'styled-components';
import {
  COLOR_ACCENT,
  COLOR_BACKGROUND_DARK,
  COLOR_BACKGROUND_LIGHT,
  COLOR_FONT_DARK,
  COLOR_FONT_INPUT,
  COLOR_FONT_LIGHT,
  COLOR_PLACEHOLDER,
} from './variables';
import { CSVLink } from 'react-csv';

export const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

  *,*::after,*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  button {
    border: none;
    outline: none;
    cursor: pointer;
  }

  ul,
  ol {
    list-style: none;
  }

  input:focus {
    outline: none;
    border: 1px solid ${COLOR_ACCENT};
  }

  textarea:focus {
    outline: none;
    border: 1px solid ${COLOR_ACCENT};
  }

  select:focus {
    outline: none;
    border: 1px solid ${COLOR_ACCENT} !important;
  }

  body {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #fafafa;
  ${({ center }) =>
    center
      ? css`
          display: flex;
          align-items: center;
          justify-content: center;
        `
      : ''}
`;

export const Button = styled.button`
  width: ${({ width }) => (width ? width : 'auto')};
  padding: 0 20px;
  border-radius: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR_ACCENT};
  color: ${COLOR_FONT_LIGHT};
  text-align: center;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: 600;
  cursor: pointer;
`;

export const ButtonOutlined = styled.button`
  width: ${({ width }) => (width ? width : 'auto')};
  padding: 0 20px;
  border-radius: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR_BACKGROUND_LIGHT};
  color: ${COLOR_ACCENT};
  border: 2px solid ${COLOR_ACCENT};
  text-align: center;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: 600;
  cursor: pointer;
`;

export const CSVLinkOutlined = styled(CSVLink)`
  text-decoration: none;
  width: ${({ width }) => (width ? width : 'auto')};
  padding: 0 20px;
  border-radius: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR_BACKGROUND_LIGHT};
  color: ${COLOR_ACCENT};
  border: 2px solid ${COLOR_ACCENT};
  text-align: center;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: 600;
  cursor: pointer;
  margin-right: 1rem;
`;

export const ButtonOutlinedLink = styled(Link)`
  width: ${({ width }) => (width ? width : 'auto')};
  padding: 0 20px;
  border-radius: 20px;
  height: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: ${COLOR_BACKGROUND_LIGHT};
  color: ${COLOR_ACCENT};
  border: 2px solid ${COLOR_ACCENT};
  text-align: center;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: 600;
  cursor: pointer;
`;

export const ButtonBorderLess = styled.button`
  width: ${({ width }) => (width ? width : 'auto')};
  padding: 0 20px;
  border-radius: 12px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: ${COLOR_FONT_DARK};
  text-align: center;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: 600;
  cursor: pointer;
`;

export const InputIcon = styled.img`
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  width: 24px;
  height: 24px;
`;

export const Input = styled.input`
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 8px;
  font-family: Poppins, sans-serif;
  color: ${COLOR_FONT_INPUT};
  padding: 0 16px 0 40px;
  background-color: ${COLOR_BACKGROUND_DARK};
  position: relative;
  overflow: visible;

  &::placeholder {
    color: ${COLOR_PLACEHOLDER};
  }
`;

export const InputDate = styled.input`
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 8px;
  font-family: Poppins, sans-serif;
  color: ${COLOR_FONT_INPUT};
  padding: 0 16px;
  background-color: ${COLOR_BACKGROUND_DARK};
  position: relative;
  overflow: visible;

  &::placeholder {
    color: ${COLOR_PLACEHOLDER};
  }
`;

export const Textarea = styled.textarea`
  height: 120px;
  width: 100%;
  border: none;
  border-radius: 8px;
  font-family: Poppins, sans-serif;
  color: ${COLOR_FONT_INPUT};
  padding: 13px 16px 16px 40px;
  background-color: ${COLOR_BACKGROUND_DARK};
  resize: none;

  @media (max-width: 420px) {
    height: 160px;
  }

  &::placeholder {
    color: ${COLOR_PLACEHOLDER};
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-row-gap: 12px;
`;

export const Title = styled.span`
  margin-bottom: 12px;
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  color: ${COLOR_ACCENT};
`;

export const LabelAddFile = styled.label`
  width: 150px;
  height: 150px;
  border-radius: 8px;
  background-color: ${COLOR_BACKGROUND_DARK};
  background-image: url(${({ url }) => url});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

export const IconSelect = styled.img`
  position: absolute;
  top: 0;
  z-index: 20000000;
  transform: translate(7.5px, 8px);
`;

export const IconWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const RemoveButton = styled.div`
  font-size: 1.5rem;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  z-index: 2000;
  top: 2px;
  left: 6px;
  color: ${COLOR_ACCENT};
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${COLOR_ACCENT};
  background-color: ${COLOR_BACKGROUND_LIGHT};
  border-radius: 30px;
  top: 0;
  left: 0;
  transform: translate(-10%, -25%);
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;

  h1 {
    padding-right: 1rem;
  }
`;
