import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Connections } from './connections';

export const AuthService = () => {
  const [isLogged, setLogged] = useState(false);
  const { pathname } = useLocation();

  useMemo(() => {
    const localToken = window.localStorage.getItem('token');
    if (localToken) setLogged(true);
  }, [pathname]);

  const setLoggedIn = async (data) => {
    toast
      .promise(Connections.login(data), {
        success: 'Zalogowano',
        loading: 'Ładowanie...',
        error: 'Błąd logowania, spróbuj ponownie',
      })
      .then(({ data }) => {
        window.localStorage.setItem('user', JSON.stringify(data.data.user));
        window.localStorage.setItem('token', data.data.token);
        setLogged(true);
      });
  };

  const setLoggedOut = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');
    setLogged(false);
  };

  return { isLogged, setLoggedIn, setLoggedOut };
};
