import React, { useState, useEffect } from 'react';
import { PageContainer } from './styles';
import Header from '../../components/header';
import ProjectsList from '../../components/naviList';
import { useLocation } from 'react-router';
import { AuthService } from '../../utils/Auth';

const Layout = ({ children }) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [, setToggled] = useState(false);
  const { pathname } = useLocation();
  const id = pathname.split('/')[pathname.split('/').length - 1];

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth > 1366) {
        setToggleMenu(false);
      }
    }
    window.addEventListener('resize', updateSize);

    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const handleToggleMenu = () => {
    setToggleMenu((state) => !state);
  };

  return (
    <>
      <Header handleToggleMenu={handleToggleMenu} toggleMenu={toggleMenu} setToggled={setToggled} />
      <PageContainer>
        <ProjectsList toggleMenu={toggleMenu} id={id} />
        {children}
      </PageContainer>
    </>
  );
};

export default Layout;
