import React, { useEffect } from 'react';
import add from '../assets/svg/add.svg';
import video from '../assets/svg/Ikonka_video.svg';
import look from '../assets/svg/Ikonka_zdjecie.svg';
import { API_URL } from '../utils/connections';
import { LabelAddFile, RemoveButton } from './atoms';

const AddFile = ({ name, handleChangeFile, data, type = 'photo', visible }) => {
  const regex = new RegExp(/^data:video/);
  return (
    <div style={{ position: 'relative' }}>
      <input
        type="file"
        id={name}
        name={name}
        hidden={true}
        accept={
          type === 'video' ? 'video/mp4,image/x-png,image/jpeg,.mp4,.png,.jpg,' : 'image/x-png,image/jpeg,.jpg,.png'
        }
        disabled={!visible}
        onChange={handleChangeFile}
      />
      {data[name]?.url && <RemoveButton onClick={() => handleChangeFile(name)}>x</RemoveButton>}
      <LabelAddFile
        url={
          data[name].url && data[name].url[0] === '/'
            ? data[name].url.split('.').pop() === 'mp4'
              ? null
              : `${API_URL}${data[name].url}`
            : regex.test(data[name].url)
            ? null
            : data[name].url
        }
        htmlFor={name}
      >
        {data[name].url ? (
          data[name].url.split('.').pop() === 'mkv' || data[name].url.split('.').pop() === 'mp4' ? (
            <img width={40} src={video} alt="Video" />
          ) : null
        ) : (
          <img width={40} src={add} alt="Add" />
        )}

        {data[name].url && data[name].url[0] === '/' ? (
          <a
            href={`${API_URL}${data[name].url}`}
            target={'_blank'}
            style={{ position: 'absolute', right: 0, top: 0, transform: 'translate(10%, -25%)' }}
          >
            <img width={30} src={look} alt="Video" />
          </a>
        ) : regex.test(data[name].url) ? (
          <img width={40} src={video} alt="Video" />
        ) : null}
      </LabelAddFile>
    </div>
  );
};

export default AddFile;
