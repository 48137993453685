import axios from 'axios';

// export const API_URL = 'http://localhost:4002';
export const API_URL = 'https://api2.arbox.kubakoduje.pl';
export const FIREBASE_URL = 'https://firebasedynamiclinks.googleapis.com/v1';

const getAuth = () => {
  return {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  };
};

export const Connections = {
  getCompanies: () => axios.get(`${API_URL}/companies`, getAuth()),
  getCompany: (id) => axios.get(`${API_URL}/companies/${id}`, getAuth()),
  addCompany: (data) => axios.post(`${API_URL}/companies/`, data, getAuth()),
  createCompany: () => axios.post(`${API_URL}`),
  getProjects: () => axios.get(`${API_URL}/projects`, getAuth()),
  getProject: (id) => axios.get(`${API_URL}/projects/${id}`, getAuth()),
  createProject: (data) => axios.post(`${API_URL}/projects`, data, getAuth()),
  deleteProject: (id) => axios.delete(`${API_URL}/projects/${id}`, getAuth()),
  updateProject: (id, data) => axios.put(`${API_URL}/projects/${id}`, data, getAuth()),
  login: (data) => axios.post(`${API_URL}/admin/login`, data),
  addUser: (data) => axios.post(`${API_URL}/admin/users`, data, getAuth()),
  confirmUser: (data) => axios.post(`${API_URL}/admin/register`, data),
  createDeepLink: (data) => axios.post(`${FIREBASE_URL}/shortLinks?key=AIzaSyCOQFQwkk_LZb4bxB5oWPN1ZC41MDePVac`, data),
};
