import styled from 'styled-components';

export const Card = styled.div`
  width: 300px;
  padding: 36px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 12px 32px 0 rgba(93, 92, 92, 0.12);
  border-radius: 16px;
`;
