import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { COLOR_ACCENT, COLOR_BACKGROUND_DARK, COLOR_BACKGROUND_LIGHT, COLOR_FONT_INPUT } from '../../global/variables';

export const ProjectsContainer = styled.div`
  overflow-y: auto;
  width: 320px;
  height: 100%;
  background-color: ${COLOR_BACKGROUND_LIGHT};
  padding: 24px 12px 36px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 12px;
  transform: ${({ toggleMenu }) => (toggleMenu ? 'translateX(100%)' : 'translateX(0)')};
  z-index: 2;

  @media (max-width: 1366px) {
    position: absolute;
    right: 100%;
    top: 0;
    box-shadow: 0 16px 48px 0 rgba(93, 92, 92, 0.16);
    transition: transform 0.5s ease-in-out;
    z-index: 2000000000;
  }
`;

export const ProjectName = styled(Link)`
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  min-height: 40px;
  display: flex;
  align-items: center;
  text-decoration: none;

  ${({ active }) =>
    active
      ? css`
          background-color: ${COLOR_ACCENT};
          color: white;
          font-weight: 600;
        `
      : css`
          background-color: ${COLOR_BACKGROUND_DARK};
          color: ${COLOR_FONT_INPUT};
        `}
`;

export const CompanyName = styled.span`
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  min-height: 40px;
  display: flex;
  align-items: center;
  text-decoration: none;

  ${({ active }) =>
    active
      ? css`
          background-color: ${COLOR_ACCENT};
          color: white;
          font-weight: 600;
        `
      : css`
          background-color: ${COLOR_BACKGROUND_DARK};
          color: ${COLOR_FONT_INPUT};
        `}
`;

export const Title = styled.h1`
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: ${COLOR_FONT_INPUT};
`;
