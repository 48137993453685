import styled from 'styled-components';
import { COLOR_FONT_INPUT } from '../../global/variables';

export const InstructionContainer = styled.div`
  //width: calc(100% - 320px);

  @media (max-width: 1366px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: ${COLOR_FONT_INPUT};
`;
