import React, { useEffect, useState } from 'react';
import AddUser from '../addUser';
import { InstructionContainer, Title } from './styles';
import AddCompany from '../addCompany';
import { CSVLinkOutlined } from '../../global/atoms';
import { toast } from 'react-hot-toast';
import { Connections } from '../../utils/connections';
import { GridContainer, GridInstructionContainer } from '../layout/styles';

const getDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();

  return dd + '-' + mm + '-' + yyyy;
};

const generateCSV = (data) => {
  const csvArr = [['Firma', 'Nazwa', 'Opis', 'Link', 'Od', 'Do']];
  data.data.forEach((company) => {
    company.projects.forEach((project) => {
      csvArr.push([company.name, project.name, project.description, project.url, project.startDate, project.endDate]);
    });
  });
  return csvArr;
};

const Instruction = () => {
  const user = window.localStorage.getItem('user');
  const [dataCSV, setDataCSV] = useState([]);
  const isAdmin = JSON.parse(user).roles[0].id === 1;
  const isModerator = JSON.parse(user).roles[0].id === 2;

  useEffect(() => {
    toast
      .promise(Connections.getCompanies(), {
        success: 'Wygenerowano raport',
        loading: 'Ładowanie...',
        error: 'Błąd generowania raportu',
      })
      .then((data) => {
        const filtred = data.data.map(({ name, id }) => ({ value: id.toString(), label: name }));
        setDataCSV(generateCSV(data));
      });
  }, []);

  return (
    <GridInstructionContainer>
      <InstructionContainer
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          textAlign: 'center',
          padding: '24px 24px 86px',
        }}
      >
        <Title style={{ fontSize: '32px', marginBottom: '36px', textAlign: 'center', width: '100%' }}>
          Witaj w panelu administracji AR Box.{' '}
        </Title>
        <Title style={{ textAlign: 'left', marginBottom: '20px', fontSize: '19px' }}>
          &emsp;&emsp;Naciskając logo Polska w Pudełku u góry na środku strony przejdziesz do strony startowej z
          instrukcją obsługi portalu.
        </Title>
        <Title style={{ textAlign: 'left', marginBottom: '20px', fontSize: '19px' }}>
          &emsp;&emsp;Po lewej stronie znajduje się lista dodanych już projektów, możesz kliknąć na wybrany projekt i
          edytować wszystkie pola danego projektu lub klikający przycisk{' '}
          <span style={{ fontWeight: 'bold', fontStyle: 'italic', color: '#7BED8D' }}>Usuń</span> w prawym dolnym rogu
          możesz usunąć dany projekt.
        </Title>
        <Title style={{ textAlign: 'left', marginBottom: '20px', fontSize: '19px' }}>
          &emsp;&emsp;Aby dodać nowy projekt wciśnij okrągły przycisk z plusem po prawej stronie u góry. Pojawi się
          formularz zawierający poszczególne elementy nowego projektu, który należy wypełnić a następnie zapisać
          klikając przycisk <span style={{ fontWeight: 'bold', fontStyle: 'italic', color: '#7BED8D' }}>Zapisz</span> po
          prawej stronie na dole.
        </Title>
        <Title style={{ textAlign: 'left', marginBottom: '20px', fontSize: '19px' }}>
          &emsp;&emsp;Pola dotyczące znaczników znajdujących się na pudełku przyjmują tylko formaty
          <span style={{ fontWeight: 'bold' }}> JPG</span> oraz <span style={{ fontWeight: 'bold' }}> PNG</span> o
          maksymalnej wadze <span style={{ fontWeight: 'bold' }}>2MB.</span> Preferowane są grafiki
          <span style={{ fontWeight: 'bold' }}> JPG</span> w <span style={{ fontWeight: 'bold' }}>24 bitowym </span>
          formacie kolorów.
        </Title>
        <Title style={{ textAlign: 'left', marginBottom: '20px', fontSize: '19px' }}>
          &emsp;&emsp;Pola dotyczące materiałów promocyjnych wyświetlanych na ściankach przyjmują formaty plików:
          <span style={{ fontWeight: 'bold' }}> JPG, PNG, MP4</span>. Maksymalna waga plików video to
          <span style={{ fontWeight: 'bold' }}> 25MB.</span>
        </Title>
        <Title style={{ textAlign: 'left', fontSize: '19px' }}>
          &emsp;&emsp;W razie kłopotów z panelem administratora prosimy kontaktować się mailowo pod adresem:
          <span style={{ fontWeight: 'bold' }}> kubakoduje@gmail.com</span>.
        </Title>

        {isAdmin && (
          <div style={{ position: 'fixed', bottom: '20px', right: '20px', display: 'flex' }}>
            <CSVLinkOutlined data={dataCSV} filename={`Dane_z_${getDate()}.csv`} target="_blank">
              Pobierz Raport
            </CSVLinkOutlined>
          </div>
        )}
      </InstructionContainer>
      <div style={{ maxWidth: '320px', margin: '0 auto' }}>
        {(isAdmin || isModerator) && <AddCompany />}
        {isAdmin && <AddUser />}
      </div>
    </GridInstructionContainer>
  );
};

export default Instruction;
