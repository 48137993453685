import React, { useEffect, useState } from 'react';
import { DateWrapper, FormContainer, GridContainer, ImageWrapper, Title, Wrapper } from '../components/layout/styles';
import InputContainer from '../global/InputContainer';
import name from '../assets/svg/name.svg';
import company from '../assets/svg/company.svg';
import link from '../assets/svg/link.svg';
import TextareaContainer from '../global/TextareaContainer';
import description from '../assets/svg/description.svg';
import { Button, ButtonOutlined, IconSelect, IconWrapper, CheckboxWrapper } from '../global/atoms';
import AddFile from '../global/AddFile';
import { toast } from 'react-hot-toast';
import { Connections } from '../utils/connections';
import Select from 'react-select';

const customSelect = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    zIndex: '98',
    background: '#F0F4F8',
    color: '#748A9D',
    borderRadius: '8px',
    fontSize: '14px',
  }),
  control: (provided) => ({
    ...provided,
    color: '#748A9D',
    background: '#F0F4F8',
    borderRadius: '8px',
    paddingLeft: '28px',
    fontSize: '14px',
    border: 'none',
  }),
  input: (provided) => ({
    ...provided,
    color: '#748A9D',
    fontSize: '14px',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#748A9D',
    fontSize: '14px',
  }),
};

const AddView = () => {
  const [data, setData] = useState({
    company: '',
    description: '',
    endDate: '',
    frontContent: '',
    frontMarker: '',
    leftContent: '',
    leftMarker: '',
    logo: '',
    name: '',
    rightContent: '',
    rightMarker: '',
    startDate: '',
    url: '',
  });
  const [companies, setCompanies] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Connections.getCompanies().then((data) => {
      const filtred = data.data.map(({ name, id }) => ({ value: id.toString(), label: name }));
      setCompanies(filtred);
    });
  }, []);

  const handleChangeInput = (event) => {
    setData({ ...data, [event.currentTarget.name]: event.currentTarget.value });
  };

  const handleChangeCheckbox = (event) => {
    setData({ ...data, [event.currentTarget.name]: event.currentTarget.checked });
  };

  const handleChangeFile = (event) => {
    if (typeof event === 'string') {
      setData((state) => ({
        ...state,
        [event]: '',
      }));
    } else {
      const file = event.currentTarget.files[0];

      const reader = new FileReader();

      reader.onload = ({ target }) => {
        if (target) {
          setData((state) => ({
            ...state,
            [event.target.name]: {
              file: file,
              url: target.result,
            },
          }));
        }
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const handleClearData = () => {
    setData({
      company: '',
      description: '',
      endDate: '',
      frontContent: '',
      frontMarker: '',
      leftContent: '',
      leftMarker: '',
      logo: '',
      name: '',
      rightContent: '',
      rightMarker: '',
      startDate: '',
      url: '',
      leftVisible: false,
      frontVisible: false,
      rightVisible: false,
    });
  };

  const handleSubmit = () => {
    const form = new FormData();

    for (const item in data) {
      if (data[item]?.file) {
        form.append(`files.${item}`, data[item].file, data[item].file.name);
      } else if (
        item === 'company' ||
        item === 'description' ||
        item === 'endDate' ||
        item === 'startDate' ||
        item === 'name'
      ) {
        form.append(item, data[item]);
      }
    }

    form.append('data', JSON.stringify({ ...data }));

    setLoading(true);

    toast
      .promise(Connections.createProject(form), {
        success: 'Utworzono projekt',
        loading: 'Tworzenie projektu...',
        error: 'Błąd tworzenia projektu',
      })
      .then((project) => {
        return toast
          .promise(
            Connections.createDeepLink({
              longDynamicLink: `https://polandinthebox.page.link/?link=https://www.polandinthebox.page.link/${project.data.id}&apn=com.JKTech.PolandInTheBoxAR&ibi=com.polandinbox.PolandInBoxAR&isi=1566844501&ofl=https://polandinbox.pl/`,
            }),
            {
              success: 'Utworzono deep link',
              loading: 'Tworzenie deep linku...',
              error: 'Błąd tworzenia deep linku',
            },
          )
          .then((res) => {
            form.set('data', JSON.stringify({ ...data, deepLinkTag: res.data.shortLink.slice(33) }));

            return toast
              .promise(Connections.updateProject(project.data.id, form), {
                success: 'Zapisano deep link',
                loading: 'Zapisywanie deep linku...',
                error: 'Błąd zapisywania deep linku. Projekt niepoprawnie zapisany. Usuń projekt i stwórz ponownie ',
              })
              .then(() => {
                setLoading(false);
                window.location.replace('/');
              })
              .catch(() => {
                setLoading(false);
              });
          })
          .catch(() => {
            toast
              .promise(Connections.deleteProject(project.data.id), {
                success: 'Projekt nie zapisany',
                loading: 'Ładowanie...',
                error: 'Projekt niepoprawnie zapisany. Usuń projekt i stwórz ponownie',
              })
              .then(() => {
                setLoading(false);
              })
              .catch(() => {
                setLoading(false);
              });
          });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.3)',
            zIndex: 99,
            top: 0,
            left: 0,
          }}
        />
      )}
      <FormContainer>
        <Wrapper
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gridRowGap: '12px',
          }}
        >
          <Title>Dane Projektu</Title>
          <InputContainer
            type={'text'}
            onChange={handleChangeInput}
            icon={name}
            value={data.name}
            placeholder={'Nazwa projektu'}
            name={'name'}
          />
          {companies && (
            <IconWrapper style={{ position: 'relative', width: '100%' }}>
              <Select
                name="company"
                options={companies}
                onChange={({ value }) => setData({ ...data, company: value })}
                styles={customSelect}
                placeholder="Firma"
              />
              <IconSelect src={company} />
            </IconWrapper>
          )}
          <InputContainer
            icon={link}
            type={'text'}
            onChange={handleChangeInput}
            value={data.url}
            placeholder={'Link w aplikacji'}
            name={'url'}
          />
          <TextareaContainer
            onChange={handleChangeInput}
            icon={description}
            value={data.description}
            placeholder={'Opis projektu w aplikacji'}
            name={'description'}
          />
          <Title>Projekt ograniczony czasowo</Title>
          <DateWrapper>
            <Wrapper style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
              <Title style={{ marginRight: '10px' }}>Od</Title>
              <InputContainer
                type={'date'}
                onChange={handleChangeInput}
                value={data.startDate}
                placeholder={'Data rozpoczęcia'}
                name={'startDate'}
              />
            </Wrapper>
            <Wrapper style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
              <Title style={{ marginRight: '10px' }}>Do</Title>
              <InputContainer
                type={'date'}
                onChange={handleChangeInput}
                value={data.endDate}
                placeholder={'Data zakończenia'}
                name={'endDate'}
              />
            </Wrapper>
          </DateWrapper>
          <ImageWrapper>
            <Title>Logo</Title>
            <AddFile name={'logo'} handleChangeFile={handleChangeFile} data={data} visible={true} />
          </ImageWrapper>
        </Wrapper>
        <Wrapper
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gridRowGap: '12px',
          }}
        >
          <Title>Pudełko</Title>
          <GridContainer>
            <ImageWrapper>
              <CheckboxWrapper>
                <Title>Widoczny</Title>
                <InputContainer
                  type={'checkbox'}
                  onChange={handleChangeCheckbox}
                  value={data.leftVisible}
                  name={'leftVisible'}
                  style={{ width: '1rem' }}
                />
              </CheckboxWrapper>
              <Title>Znacznik lewej ściany</Title>
              <AddFile name={'leftMarker'} handleChangeFile={handleChangeFile} data={data} visible={data.leftVisible} />
            </ImageWrapper>
            <ImageWrapper>
              <CheckboxWrapper>
                <Title>Widoczny</Title>
                <InputContainer
                  type={'checkbox'}
                  onChange={handleChangeCheckbox}
                  value={data.frontVisible}
                  name={'frontVisible'}
                  style={{ width: '1rem' }}
                />
              </CheckboxWrapper>
              <Title>Znacznik przedniej ściany</Title>
              <AddFile
                name={'frontMarker'}
                handleChangeFile={handleChangeFile}
                data={data}
                visible={data.frontVisible}
              />
            </ImageWrapper>
            <ImageWrapper>
              <CheckboxWrapper>
                <Title>Widoczny</Title>
                <InputContainer
                  type={'checkbox'}
                  onChange={handleChangeCheckbox}
                  value={data.rightVisible}
                  name={'rightVisible'}
                  style={{ width: '1rem' }}
                />
              </CheckboxWrapper>
              <Title>Znacznik prawej ściany</Title>
              <AddFile
                name={'rightMarker'}
                handleChangeFile={handleChangeFile}
                data={data}
                visible={data.rightVisible}
              />
            </ImageWrapper>
          </GridContainer>
          <GridContainer>
            <ImageWrapper>
              <Title>Materiał graficzny - ściana lewa</Title>
              <AddFile
                name={'leftContent'}
                type={'video'}
                handleChangeFile={handleChangeFile}
                data={data}
                visible={data.leftVisible}
              />
            </ImageWrapper>
            <ImageWrapper>
              <Title>Materiał graficzny - ściana przednia</Title>
              <AddFile
                name={'frontContent'}
                type={'video'}
                handleChangeFile={handleChangeFile}
                data={data}
                visible={data.frontVisible}
              />
            </ImageWrapper>
            <ImageWrapper>
              <Title>Materiał graficzny - ściana prawa</Title>
              <AddFile
                name={'rightContent'}
                type={'video'}
                handleChangeFile={handleChangeFile}
                data={data}
                visible={data.rightVisible}
              />
            </ImageWrapper>
          </GridContainer>
        </Wrapper>
      </FormContainer>
      <div style={{ position: 'fixed', bottom: '20px', right: '20px', display: 'flex' }}>
        <Button onClick={handleSubmit} style={{ marginRight: '10px' }}>
          Dodaj
        </Button>
        <ButtonOutlined onClick={handleClearData}>Wyczyść</ButtonOutlined>
      </div>
    </>
  );
};

export default AddView;
