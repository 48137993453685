import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import Security from './views/security/Security';
import { Toaster } from 'react-hot-toast';
import { AuthService } from './utils/Auth';
import { BrowserRouter as Router } from 'react-router-dom';
import history from './utils/history';
import Layout from './components/layout';
import Instruction from './components/instruction';
import AddView from './views/AddView';
import ProjectView from './views/ProjectView';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLogged } = AuthService();
  return (
    <Route {...rest} render={(props) => (isLogged === true ? <Component {...props} /> : <Redirect to="/login" />)} />
  );
};

function App() {
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route exact path={'/login'}>
            <Security />
          </Route>
          <Layout>
            <PrivateRoute exact path="/" component={Instruction} />
            <PrivateRoute exact path="/add" component={AddView} />
            <PrivateRoute exact path="/project/:id" component={ProjectView} />
          </Layout>
        </Switch>

        <Toaster
          toastOptions={{
            loading: {
              duration: 60000,
            },
          }}
        />
      </Router>
    </>
  );
}

export default App;
