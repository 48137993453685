import styled, { css } from 'styled-components';
import { COLOR_ACCENT, COLOR_BACKGROUND_DARK, COLOR_BACKGROUND_LIGHT, COLOR_FONT_INPUT } from '../../global/variables';

export const PageContainer = styled.div`
  display: grid;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  height: calc(100vh - 60px);
  grid-template-columns: 320px 1fr;
  grid-template-rows: 1fr;
  position: relative;

  @media (max-width: 1366px) {
    display: block;
  }
`;

export const FormContainer = styled.div`
  padding: 24px 24px 86px;
  display: grid;
  grid-template-columns: 1fr 474px;
  grid-column-gap: 24px;
  grid-row-gap: 12px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-row-gap: 48px;
  }
`;

export const Wrapper = styled.div``;

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-column-gap: 12px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1024px) {
    width: 474px;
    margin: 0 auto;
  }

  @media (max-width: 535px) {
    width: 150px;

    grid-template-columns: 150px;
    grid-row-gap: 12px;
  }
`;
export const GridInstructionContainer = styled.div`
  width: 100%;
  display: grid;
  grid-column-gap: 12px;
  grid-template-columns: 1fr 320px;
  padding: 0 24px;

  @media (max-width: 1366px) {
    grid-template-columns: 1fr;
  }
`;

export const Title = styled.h1`
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: ${COLOR_FONT_INPUT};
`;

export const InstructionContainer = styled.div`
  width: calc(100% - 320px);

  @media (max-width: 1366px) {
    width: 100%;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  grid-row-gap: 12px;
  flex-direction: column;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  width: 100%;

  @media (max-width: 490px) {
    flex-wrap: wrap;
  }
`;
