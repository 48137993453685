import React, { useState } from 'react';
import { Wrapper } from '../addUser/styles';
import { Button, Title } from '../../global/atoms';
import InputContainer from '../../global/InputContainer';
import toast from 'react-hot-toast';
import { Connections } from '../../utils/connections';
import { useHistory } from 'react-router';
import companysvg from '../../assets/svg/company.svg';

const AddCompany = () => {
  const [company, setCompany] = useState('');
  const history = useHistory();

  const handleChangeInput = (event) => {
    setCompany(event.currentTarget.value);
  };

  const submit = () => {
    toast
      .promise(Connections.addCompany({ name: company }), {
        success: 'Firma została utworzona',
        loading: 'Ładowanie...',
        error: 'Błąd tworzenia firmy, spróbuj ponownie.',
      })
      .then(() => {
        window.location.reload();
      });
  };

  return (
    <Wrapper>
      <Title>Dodaj nową firmę</Title>
      <InputContainer
        icon={companysvg}
        type={'text'}
        onChange={handleChangeInput}
        value={company.email}
        placeholder={'Nazwa Firmy'}
        name={'company'}
      />
      <Button onClick={submit} style={{ marginTop: '1rem' }}>
        Dodaj firmę
      </Button>
    </Wrapper>
  );
};

export default AddCompany;
