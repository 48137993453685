import React, { useEffect, useState } from 'react';
import { ProjectsContainer, Title, ProjectName, CompanyName } from './styles';
import { toast } from 'react-hot-toast';
import { useHistory, useLocation, useParams } from 'react-router';
import { Connections } from '../../utils/connections';
import { Button, ButtonOutlined, ButtonOutlinedLink } from '../../global/atoms';
import { COLOR_BACKGROUND_DARK, COLOR_ACCENT } from '../../global/variables';
import { AuthService } from '../../utils/Auth';

const ProjectsList = ({ toggleMenu, id }) => {
  const [projects, setProjects] = useState([]);
  const [companiesProjects, setCompaniesProjects] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyIndex, setSelectedCompanyIndex] = useState(null);
  const [toggleView, setToggleView] = useState(false);
  const { isLogged } = AuthService();

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/') {
      setSelectedCompanyIndex(null);
    }
    if (pathname === '/add') {
      setSelectedCompanyIndex(null);
    }
  }, [pathname]);

  useEffect(() => {
    if (isLogged) {
      toast
        .promise(Promise.all([Connections.getProjects(), Connections.getCompanies()]), {
          success: 'Pobrano dane firm i projektów',
          loading: 'Ładowanie...',
          error: 'Błąd pobierania danych firm i projektów',
        })
        .then((data) => {
          setProjects(data[0].data);
          setCompanies(data[1].data);
        });
    }
  }, [isLogged]);

  useEffect(() => {
    if (companies[selectedCompanyIndex]) {
      setCompaniesProjects(companies[selectedCompanyIndex].projects);
    }
  }, [selectedCompanyIndex]);

  return (
    <>
      <ProjectsContainer toggleMenu={toggleMenu}>
        <ButtonOutlinedLink to="/add">Dodaj projekt</ButtonOutlinedLink>
        <div style={{ display: 'flex' }}>
          <Button
            style={{
              backgroundColor: !toggleView ? COLOR_BACKGROUND_DARK : '',
              color: !toggleView ? COLOR_ACCENT : '',
              marginRight: '12px',
              flex: '1',
            }}
            onClick={() => {
              setToggleView(true);
              setSelectedCompanyIndex(null);
            }}
          >
            Lista projektów
          </Button>
          <Button
            style={{
              flex: '1',
              backgroundColor: toggleView ? COLOR_BACKGROUND_DARK : '',
              color: toggleView ? COLOR_ACCENT : '',
            }}
            onClick={() => {
              setToggleView(false);
              setSelectedCompanyIndex(null);
            }}
          >
            Lista firm
          </Button>
        </div>
        {toggleView &&
          projects.map((item) => (
            <ProjectName key={item.id} data-id={item.id} to={`/project/${item.id}`} active={item.id === +id}>
              {item.name}
            </ProjectName>
          ))}
        {!toggleView &&
          (selectedCompanyIndex === null ? (
            companies.map((company, index) => (
              <CompanyName
                key={company.id}
                data-id={company.id}
                onClick={() => setSelectedCompanyIndex(index)}
                active={company.id === companies[selectedCompanyIndex]?.id}
              >
                {company.name}
              </CompanyName>
            ))
          ) : (
            <>
              <Title>{companies[selectedCompanyIndex].name}</Title>
              {companiesProjects.map((item) => (
                <ProjectName key={item.id} data-id={item.id} to={`/project/${item.id}`} active={item.id === +id}>
                  {item.name}
                </ProjectName>
              ))}
            </>
          ))}
      </ProjectsContainer>
    </>
  );
};

export default ProjectsList;
