import React, { useState } from 'react';
import { Button, Title } from '../../global/atoms';
import InputContainer from '../../global/InputContainer';
import { Wrapper } from './styles';
import { Connections } from '../../utils/connections';
import toast from 'react-hot-toast';
import user from '../../assets/svg/name.svg';
import pass from '../../assets/svg/password.svg';

const AddUser = () => {
  const [values, setValues] = useState({
    email: '',
    password: '',
  });

  const isValidPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm;
  const handleChangeInput = (event) => {
    setValues({ ...values, [event.currentTarget.name]: event.currentTarget.value });
  };
  const submitUser = () => {
    if (!values.password.match(isValidPassword)) {
      return alert('Hasło musi składać się z 8 znaków, jednej wielkiej litery i cyfry');
    }
    toast
      .promise(
        Connections.addUser({
          email: values.email,
          firstname: ' ',
          lastname: ' ',
          roles: [2],
        }),
        {
          success: 'Użytkownik został utworzony',
          loading: 'Ładowanie...',
          error: (err) => 'Email jest zajęty.',
        },
      )
      .then(({ data }) => {
        const token = data.data.registrationToken;
        toast
          .promise(
            Connections.confirmUser({
              registrationToken: token,
              userInfo: {
                firstname: ' ',
                lastname: ' ',
                password: values.password,
              },
            }),
            {
              success: 'Hasło zostało zapisane',
              loading: 'Ładowanie...',
              error: (err) => 'Hasło musi mieć jedną wielką literę',
            },
          )
          .then((data) => {
            setValues({ email: '', password: '' });
          });
      });
  };
  return (
    <Wrapper>
      <Title>Dodaj nowego użytkownika</Title>
      <div style={{ width: '100%', marginBottom: '1rem' }}>
        <InputContainer
          type={'email'}
          icon={user}
          onChange={handleChangeInput}
          value={values.email}
          placeholder={'Adres email'}
          name={'email'}
          // style={{ marginBottom: '1rem' }}
        />
      </div>
      <InputContainer
        type={'password'}
        onChange={handleChangeInput}
        value={values.password}
        placeholder={'Hasło'}
        name={'password'}
        icon={pass}
      />
      <Button onClick={submitUser} style={{ marginTop: '1rem' }}>
        Dodaj użytkownika
      </Button>
    </Wrapper>
  );
};

export default AddUser;
