import styled from 'styled-components';
import { COLOR_BACKGROUND_DARK } from '../../global/variables';

export const Wrapper = styled.header`
  width: 100%;
  height: 60px;
  background-color: ${COLOR_BACKGROUND_DARK};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
`;

export const HamburgerButton = styled.div`
  visibility: hidden;

  @media (max-width: 1366px) {
    visibility: visible;
    background-color: ${COLOR_BACKGROUND_DARK};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;
