import React from 'react';
import { Button } from '../../global/atoms';
import { AuthService } from '../../utils/Auth';
import { COLOR_ACCENT } from '../../global/variables';
import logo from '../../assets/images/logo.png';
import Hamburger from 'hamburger-react';
import { Wrapper, HamburgerButton } from './styles';
import history from '../../utils/history';
import { Link } from 'react-router-dom';

const Header = ({ handleToggleMenu, toggleMenu, setToggled }) => {
  const { setLoggedOut } = AuthService();
  return (
    <Wrapper>
      <HamburgerButton onClick={handleToggleMenu}>
        <Hamburger color={COLOR_ACCENT} toggled={toggleMenu} toggle={setToggled} />
      </HamburgerButton>
      <Link to="/">
        <img width={75} src={logo} alt={'Logo'} style={{ cursor: 'pointer' }} />
      </Link>
      <Button
        onClick={() => {
          setLoggedOut();
          window.location.reload();
        }}
        style={{ height: '40px' }}
      >
        Wyloguj
      </Button>
    </Wrapper>
  );
};

export default Header;
