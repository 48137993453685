import React from 'react';
import { InputIcon } from './atoms';
import { Textarea } from './atoms';
import { COLOR_FONT_INPUT } from './variables';

const TextareaContainer = ({ icon, onChange, value, placeholder, name }) => {
  return (
    <div style={{ width: '100%', position: 'relative' }}>
      {icon && <InputIcon src={icon} alt="action" style={{ top: '22px' }} />}
      <Textarea onChange={onChange} value={value} placeholder={placeholder} name={name} maxLength={200} />
      <span
        style={{ position: 'absolute', right: '8px', bottom: '8px', color: `${COLOR_FONT_INPUT}`, fontSize: '12px' }}
      >
        {value.length}/200
      </span>
    </div>
  );
};

export default TextareaContainer;
