import React, { useState, useEffect } from 'react';
import { Button, Form, PageContainer, Title } from '../../global/atoms';
import { Card } from './styles';
import user from '../../assets/svg/name.svg';
import pass from '../../assets/svg/password.svg';
import InputContainer from '../../global/InputContainer';

import toast from 'react-hot-toast';
import { Redirect } from 'react-router';
import { AuthService } from '../../utils/Auth';

const Security = () => {
  const [data, setData] = useState({ email: '', password: '' });
  const { isLogged, setLoggedIn } = AuthService();

  useEffect(() => {}, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    setLoggedIn(data);
  };

  const handleChangeInput = (event) => {
    setData({ ...data, [event.currentTarget.name]: event.currentTarget.value });
  };

  if (isLogged) {
    return <Redirect to="/" />;
  }

  return (
    <PageContainer center>
      <Card>
        <Form onSubmit={handleSubmit}>
          <Title>Logowanie</Title>
          <InputContainer
            icon={user}
            type={'email'}
            onChange={handleChangeInput}
            value={data.email}
            placeholder={'Email'}
            name={'email'}
          />
          <InputContainer
            icon={pass}
            type={'password'}
            onChange={handleChangeInput}
            value={data.password}
            placeholder={'Password'}
            name={'password'}
          />
          <Button style={{ marginTop: '16px' }} type={'submit'} width={'100%'}>
            Zaloguj się
          </Button>
        </Form>
      </Card>
    </PageContainer>
  );
};

export default Security;
