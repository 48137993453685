import React from 'react';
import { Input, InputDate, InputIcon } from './atoms';

const InputContainer = ({ icon, type, onChange, value, placeholder, name, style }) => {
  return (
    <div style={{ width: '100%', position: 'relative' }}>
      {icon && <InputIcon src={icon} alt="action" />}
      {type === 'checkbox' ? (
        <Input type={type} onChange={onChange} checked={value} placeholder={placeholder} name={name} style={style} />
      ) : (
        <>
          {type === 'date' ? (
            <InputDate type={type} onChange={onChange} value={value} placeholder={placeholder} name={name} />
          ) : (
            <Input type={type} onChange={onChange} value={value} placeholder={placeholder} name={name} style={style} />
          )}
        </>
      )}
    </div>
  );
};

export default InputContainer;
